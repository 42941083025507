import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links"

import GlobalStyles from '../atoms/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../utils/styling';

import Header from "../molecules/Header"
import Footer from "../molecules/Footer"

import Bod from "../organisms/Bod"
import StrategicAdvisors from "../organisms/StrategicAdvisors"
import Staff from "../organisms/Staff"
import Headshot from "../molecules/Headshot"

import eventLockup from "../../assets/images/bridge-2023-badge.svg"
import colorStripeThin from "../../assets/images/color-stripe-thin.svg"
import westCoast from "../../assets/images/westcoast.jpg"
import "../layout.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {

  // init UIkit
  useEffect(() => {
    const UIkit = require("uikit/dist/js/uikit.min")
    const icons = require("uikit/dist/js/uikit-icons.min")
    UIkit.use(icons)
  }, [])

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const hasLogo = frontmatter.logo
  const hasMenu = frontmatter.menu
  const hasCtaButton = frontmatter.ctaButton
  const hasSidebar = frontmatter.sidebar?.length > 0 || (frontmatter.steeringCommittee && !frontmatter.showSteeringCommitteeInMain);
  const containerClass = frontmatter.containerClass || 'uk-container-large';
  const template = frontmatter.template || 'default';

  const preprocess = (html) => {
    return html
      .replace(/<!--[\s\S]*?-->/g, '')
      // .replace(/%%video%%/g, `<video autoPlay muted><source src=${EventVideo} type="video/mp4" /></video>`)
  };
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles/>
        <Header siteTitle={frontmatter.title} />
        <main>
          <section className={`uk-section uk-section-primary bg-` + frontmatter.color}>
            <div className={`uk-container ${containerClass}`}>
              <div data-uk-grid="true">

                {template === 'blog' &&
                  <div className="uk-width-1-6@m">
                    &nbsp;
                  </div>
                }

                {/* Left hand sidebar */}
                { hasLogo || hasMenu || hasCtaButton
                  ?
                    <div className="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m uk-width-1-5@l">
                      <div data-uk-sticky="offset: 60; media: 980">

                        {/* Logo */}
                        { frontmatter.logo && 
                          <SidebarLogoWrapper>
                            <Logo 
                              src={frontmatter.logo?.publicURL} 
                              alt={frontmatter.title}
                              />
                          </SidebarLogoWrapper>
                        }

                        {/* Menu */}
                        { frontmatter.menu && 
                          <SidebarMenu className="uk-list uk-list-divider">
                            {frontmatter.menu.map(({link, label}) => (
                              <li key={label}>
                                <AnchorLink to={link}>{label}</AnchorLink>
                              </li>
                            ))}
                          </SidebarMenu>
                        }

                        {/* CTA Button */}
                        <hr style={{marginTop: `0px`, paddingTop: `0px`}} />
                        { frontmatter.ctaButton && 
                          <CTAWrapper>
                            <div data-uk-lightbox={frontmatter.ctaModal} className="uk-margin">
                              <p className="uk-text-uppercase" style={{paddingRight: `50px`}}><strong>{frontmatter.ctaLead}</strong></p>
                              <a data-type={frontmatter.ctaModal && 'iframe'} className={'uk-button uk-button-primary uk-border-pill button ' + frontmatter.color} 
                                href={frontmatter.ctaLink}>{frontmatter.ctaButton}</a>
                            </div>
                          </CTAWrapper>
                        }

                        { frontmatter.showQuotesOnLeft && 
                          <div>
                            {frontmatter.quotes && <><br /><hr /><br /></>}
                            {frontmatter.quotes?.map(quote => (
                              <div key={quote.author}>
                                <p class="quote" style={{fontSize: `1rem`}}>{quote.quote}</p>
                                <div style={{maxWidth: `110px`}}>
                                  <Headshot node={quote}/>
                                </div>
                                <Meta><strong>{quote.author}</strong><br />
                                  {quote.authorTitle}<br />
                                  {quote.authorCompany}
                                </Meta>
                              </div>
                            ))}
                          </div>
                        }


                    </div></div>
                  : ''
                }

                {/* Main content */}
                <div className="uk-width-expand">
                  {/*<h2>{frontmatter.title}</h2>*/}
                  <div id="overview">

                    <LogoBackground>
                      <Image data-uk-svg="true" src={eventLockup} alt="BRIDGE 2023: Build an Inclusive Workplace, Workforce and Marketplace | April 23-25, 2023 | West Coast"/>

                      <h1 className="uk-text-center">Pioneering DEI<br /> Next Practices</h1>

                      <h2 className="uk-text-center uk-margin-bottom uk-margin-remove-top">April 23 - 25, 2023 | West Coast</h2>

                      {/* CTA Button */}
                      { frontmatter.ctaButton && 
                        <CTAWrapper>
                          <div data-uk-lightbox={frontmatter.ctaModal} className="uk-margin uk-text-center">
                            <p className="uk-text-uppercase" style={{paddingRight: `50px`}}><strong>{frontmatter.ctaLead}</strong></p>
                            <a data-type={frontmatter.ctaModal && 'iframe'} className={'uk-button uk-button-large uk-button-primary uk-border-pill button button-large ' + frontmatter.color} 
                              href={frontmatter.ctaLink}>{frontmatter.ctaButton}</a>
                          </div>
                        </CTAWrapper>
                      }
                    </LogoBackground>
                    <Image style={{width: '100%'}} data-uk-svg="true" src={colorStripeThin} alt="BRIDGE Color Stripe"/>
                    


                    {/* <hr className="uk-divider-icon" /> */}
                    <div
                      className="subpage-content"
                      dangerouslySetInnerHTML={{ 
                        __html: preprocess(html)
                      }}
                    />
                    <hr className="uk-divider-icon" />

                    <h2 id="agenda" className="uk-text-center">Check back soon for the 2023 agenda.</h2>

                    {/* {frontmatter.video && 
                    <video autoPlay muted>
                      <source src={EventVideo} type="video/mp4" />
                    </video>
                    } */}

                    {frontmatter.showSteeringCommitteeInMain && frontmatter.steeringCommittee && <><hr /><h2>
                      
                      {frontmatter.steeringCommitteeHeading || "Steering Committee"}

                    </h2></>}

                    {frontmatter.showSteeringCommitteeInMain && frontmatter.steeringCommittee && 
                      <div data-uk-grid="true" className="uk-child-width-1-2 uk-child-width-1-3@m">
                        {frontmatter.steeringCommittee?.map(member => (
                          <div key={member.name} className="uk-margin-medium-bottom">
                            <Headshot node={member} styles={{maxWidth: `160px`}} />
                          </div>
                        ))}
                      </div>
                    }

                    <div className="uk-visible@m">
                      {frontmatter.quotes && !frontmatter.showQuotesOnLeft && <><br /><hr /><br /></>}
                      {!frontmatter.showQuotesOnLeft && frontmatter.quotes?.map(quote => (
                        <div key={quote.author}>
                          <p class="quote" style={{fontSize: `1rem`}}>{quote.quote}</p>
                          <div style={{maxWidth: `110px`}}>
                            <Headshot node={quote}/>
                          </div>
                          <Meta><strong>{quote.author}</strong><br />
                            {quote.authorTitle}<br />
                            {quote.authorCompany}
                          </Meta>
                        </div>
                      ))}
                    </div>

                  </div>  
                </div>
                { hasSidebar && 
                  <div className="uk-width-1-1 uk-width-1-4@l">
                    {frontmatter.sidebar}

                    {frontmatter.steeringCommittee && !frontmatter.showSteeringCommitteeInMain && <h3 id="steering-committee">Steering Committee</h3>}
                    {frontmatter.steeringCommittee && !frontmatter.showSteeringCommitteeInMain && 
                      <div data-uk-grid="true" className="uk-child-width-1-2">
                      {frontmatter.steeringCommittee?.map(member => (
                        <div key={member.name}>
                          <Headshot node={member} styles={{maxWidth: `130px`}} />
                        </div>
                      ))}
                      </div>
                    }
                    
                    {frontmatter.steeringCommittee && !frontmatter.showSteeringCommitteeInMain && <hr className="uk-margin-medium-bottom" />}

                    <div className="uk-hidden@m">
                      {frontmatter.quotes && <><br /><hr /><br /></>}
                      {frontmatter.quotes?.map(quote => (
                        <div key={quote.author}>
                          <p class="quote" style={{fontSize: `1rem`}}>{quote.quote}</p>
                          <div style={{maxWidth: `110px`}}>
                            <Headshot node={quote}/>
                          </div>
                          <Meta><strong>{quote.author}</strong><br />
                            {quote.authorTitle}<br />
                            {quote.authorCompany}
                          </Meta>
                        </div>
                      ))}
                    </div>

                    {frontmatter.researchTeam && <h3>Research Team</h3>}
                    {frontmatter.researchTeam?.map(member => (
                      <div key={member.name} className="uk-margin-medium-bottom">
                        <Headshot node={member} styles={{maxWidth: `90px`}} />
                      </div>
                    ))}

                  </div> 
                }

                {template === 'blog' &&
                  <div className="uk-width-1-6@m">
                    <div data-uk-sticky="offset: 60; media: 980">

                      <h4 className="uk-margin-large-top">Authored by:</h4>

                      {frontmatter.blogAuthors?.map(blogAuthor => (
                        <div key={blogAuthor.author}>
                          <div style={{maxWidth: `140px`}}>
                            <Headshot node={blogAuthor} compact={true}/>
                          </div>
                          <Meta><strong>{blogAuthor.author}</strong><br />
                            {blogAuthor.authorTitle}<br />
                            {blogAuthor.authorCompany}
                          </Meta>
                        </div>
                      ))}
                    </div>
                  </div>
                }

              </div>
            </div>
          </section>
        </main>
        {frontmatter.showAllBoards === true && 
          <>
            <Bod/>
            <Staff/>
            <StrategicAdvisors/>
          </>
        }
        {template === 'blog' &&
          <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-628c0e5a180b7a69"></script>
        }
        <Footer/>
      </>
    </ThemeProvider>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        # date(formatString: "MMMM DD, YYYY")
        slug
        title
        color
        template
        sidebar
        logo {
          publicURL
        }
        menu {
          label
          link
        }
        ctaLead
        ctaButton
        ctaLink
        ctaModal
        showAdvisoryBoard
        video
        containerClass
        quotes {
          quote
          author
          authorTitle
          authorCompany
          headshot {
            childImageSharp {
              fluid(maxWidth: 180) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        blogAuthors {
          author
          authorTitle
          authorCompany
          headshot {
            childImageSharp {
              fluid(maxWidth: 180) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        researchTeam {
          name
          jobtitle
          company
          linkedin
          headshot {
            childImageSharp {
              fluid(maxWidth: 180) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        showSteeringCommitteeInMain
        steeringCommittee {
          name
          jobtitle
          company
          linkedin
          position
          headshot {
            childImageSharp {
              fluid(maxWidth: 180) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        steeringCommitteeHeading
        showQuotesOnLeft
        showAllBoards
      }
    }
  }
`

const SidebarLogoWrapper = styled.div`
  background-color: white;
  height: 130px;
  border-radius: 300px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 640px) {
    margin-left: -100px;
    /* margin-left: -5000px; */
    text-align: right;
  }  
`;

const Logo = styled.img`
  padding: 20px 20px;
  max-height: 120px;
  width: 100%;
  object-fit: contain;
  /* object-position: calc(100% - 82px); */
  @media screen and (min-width: 960px) {
    object-position: 80px;
  }  
`;

const Meta = styled.p`
  margin-top: -30px;
  font-size: 18px;
  line-height: 1.1rem;
`;

const SidebarMenu = styled.ul`
  margin-top: 60px;
  /* padding: 0 15px 0 50px; */
`

const CTAWrapper = styled.div`
  @media screen and (max-width: 959px) {
    text-align: center;
  }
`

const Image = styled.img`
  margin: 0 auto;
  display: flex;
`;

const LogoBackground = styled.div`
  background: linear-gradient(to bottom, ${theme.colors.lightPurple} 0%, rgb(35, 12, 56, 0.5) 100%), url(${westCoast});
  background-size: 100%, 240%;
  @media screen and (max-width: 959px) {
    background-size: cover, cover;
  }
  background-position: center center, 60% center;
  background-repeat: no-repeat, no-repeat;
  padding: 60px 20px;
`

Template.propTypes = {
  data: PropTypes.string,
}

Template.defaultProps = {
  data: ``,
}